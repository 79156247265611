import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ArticleTopicsList from "../components/article-topics-list";

import articleStyles from "./article.module.css";

class ArticleTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const { article } = data.cms;

    console.log(article);

    return (
      <Layout>
        <SEO title={`${article.title} | Help Center`}/>
        <div className={articleStyles.articlePageWrapper}>
          <div className={articleStyles.content}>
            <h1>{article.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: article.richText.html }}/>
          </div>
          <div className={articleStyles.articleTopicsContainer}>
            <ArticleTopicsList/>
          </div>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query($slug: String!) {
    cms {
      article(where: { slug: $slug }) {
        title
        richText {
          html
        }
      }
    }
  }
`;

export default ArticleTemplate;
